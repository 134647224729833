
// Libraries
import styled, { css } from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Icons
import DownloadIcon from "../assets/icons/icon-download.svg"

const StyledDownloadsSection = styled.section`
display: ${props => (props.viewAllowed ? "block" : "none")};
padding: 60px 10px;
${props =>
  props.backgroundcolor === "gray"
    ? css`
        background-color: ${colors.grayLight};
        }
      `
    : `background-color: ${colors.white};`}

${breakpoint.small`
 flex-wrap: nowrap;
 padding: 100px 10px;
 `
}

.block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 40px;

    ${breakpoint.small`
        flex-wrap: nowrap;
    `}

a {
    color: ${colors.charcoal};

    &:hover {
        color: ${colors.orange};
    }
}

&--image {
    width: auto;
    margin: 0 auto;

    ${breakpoint.small`
        padding-top: 30px;
    `}
    }
}

.block__content,
.block__material {
    width: 100%;

    h2 {
        font-size: 36px;
        text-align: center;
    }

    & > p > a {
        color: ${colors.orange};
    
        &:hover {
            color: ${colors.charcoal};
        }
      }



h3 {
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1em;
    font-size: 27px;

    ${breakpoint.small`
        text-align: left;
    `}

    &::before {
        content: ">>";
        position: relative;
        top: 2px;
        margin-right: 12px;
        letter-spacing: -6px;
        color: ${colors.orange};
        font-size: 30px;
    }

    .subtitle {
        font-size: 17px;
        font-weight: 500;
    }
}

h4 {
    font-size: 21px;
    margin-block: 20px 10px;
    border-bottom: 1px solid ${colors.purpleLightBg};
    padding-bottom: 5px;
}

.company {
    font-size: 25px;
    color: ${colors.purpleDark};
    line-height: 1em;
    text-transform: uppercase;
}

.note {
    line-height: 21px;
    font-size: 14px;
}

.downloadsList {
    padding-bottom: 2rem;

    ul {
        max-width: 400px;

        ${breakpoint.large`
            max-width: 500px;
        `}
    }

    li {
        line-height: 24px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: ${colors.charcoal};
            font-size: 15px;

            &::before {
                content: url(${DownloadIcon});
                position: relative;
                top: 4px;
                margin-right: 12px;
                margin-top: -5px;
            }

            ${breakpoint.medium`
            font-size: 17px;
            `}


        &:hover {
            color: ${colors.orange};
        }

        }
    }
}
}

.thumbnails_row {
    display: grid;
    grid-auto-flow: row;
    margin: auto 0;
    grid-gap: 0;
    row-gap: 1rem;
    padding-bottom: 20px;

    ${breakpoint.xs`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    `}

    ${breakpoint.small`
        grid-template-columns: repeat(3, 1fr);
    `}

    .image_column {
        padding-bottom: 20px;
        margin: 0 auto;

        ${breakpoint.small`
            margin: 0;
        `}

        p {
            font-size: 17px;
            line-height: 1.2em;
            margin-top: 10px;
            text-align: center;

            ${breakpoint.small`
                text-align: left;
            `}
        }
    }

    .thumbnail_image {
        border: 1px solid #333;
    }

    .thumbnailLink {
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }

    i {
        font-size: 15px;
    }

}


.orangeButton {
	background-color: ${colors.orange};
	color: ${colors.charcoal}!important;
	width: 100%;
	min-width: 150px;
	max-width: 200px;
	height: 45px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 12px 18px;
	border-radius: 100px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	box-sizing: border-box;
	transition: all .3s ease;

	  &:hover {
	  background-color: ${colors.charcoal};
	  color: ${colors.white}!important;
	}
}

.formsNote { font-size: 15px!important; }


a.orangeLink {
    color: ${colors.orange};

    &:hover {
        color: ${colors.charcoal};
    }
}
`

export default StyledDownloadsSection