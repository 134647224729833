import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"
import { Button } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 40px;

a {
    color: ${colors.orange};

    &:hover {
        color: ${colors.charcoal};
    }
}


`

class StiPatient extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			section: ""
		}
	}

	handleSectionChange = (selSection) => {
		const inactiveSections = document.querySelectorAll("[data-filter='sexual-health'][data-section]")
		inactiveSections.forEach(section => {
			section.classList.remove("visibleBlock")
		})
		const activeSections = document.querySelectorAll("[data-section='" + selSection + "']")
		activeSections.forEach(section => {
			section.classList.add("visibleBlock")
		})
	}

	render = props => (
		<DownloadsSection
			id="sti-patient-education"
			data-filter="sexual-health" data-filter-type="devices" data-section="patient"
		>
			<StyledContainer>
				<div className="block">
					<div className="block__material">
						<h3>Patient Education</h3>
						<div className="thumbnails_row">
							<div className="image_column">
								<a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health_Educational-Brochure.pdf`} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/sexual-health-test/sti-educational-brochure.png"
										width={350}
										alt="STI-PT Edu Brochure"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health_Educational-Brochure.pdf`} target="_blank" rel="noopener noreferrer">STI Educational Brochure*</a></p>
							</div>
							<div className="image_column">
								<a href="/resources/press-kit/" target="_blank" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/press-kit.png"
										width={350}
										alt="Press Kit"
										className="thumbnail_image"
									/>
								</a>
								<p><a href="/resources/press-kit/" target="_blank" className="thumbnailLink">Press Kit</a></p>
							</div>
						</div>
						<div className="block__material">
							<h4>Auxiliary Resources available from <a href="https://www.cdc.gov/std/saw/gyt/materials.htm" className="orangeLink" target="_blank" rel="noopener noreferrer">Center for Disease Control and Prevention (CDC)</a></h4>
							<div className="thumbnails_row">
								<div className="image_column">
									<a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Waiting-Room-Displays-English.pptx`} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
										<StaticImage
											src="../../../assets/images/visby-university/sexual-health-test/get-yourself-tested.png"
											width={350}
											alt="Waiting Room Displays"
											className="thumbnail_image"
										/>
									</a>
									<p><a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Waiting-Room-Displays-English.pptx`} target="_blank" rel="noopener noreferrer">Waiting Room Displays-English</a></p>
								</div>
								<div className="image_column">
									<a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Waiting-Room-Displays-Spanish.pptx`} target="_blank" rel="noopener noreferrer" className="thumbnailLink">
										<StaticImage
											src="../../../assets/images/visby-university/sexual-health-test/get-yourself-tested-esp.png"
											width={350}
											alt="Waiting Room Displays Spanish"
											className="thumbnail_image"
										/>
									</a>
									<p><a href={`/visby-university/sexual-health-test/Visby-Medical-Sexual-Health-Waiting-Room-Displays-Spanish.pptx`} target="_blank" rel="noopener noreferrer">Waiting Room Displays-Spanish</a></p>
								</div>
								<div className="image_column">
									<a href="https://www.cdc.gov/std/prevention/lowdown/the_lowdown_infographic_poster_30x20.pdf" target="_blank" rel="noopener noreferrer" className="thumbnailLink">
										<StaticImage
											src="../../../assets/images/visby-university/sexual-health-test/waiting-room-poster.png"
											width={350}
											alt="Waiting Room Posters (20x30)"
											className="thumbnail_image"
										/>
									</a>
									<p><a href="https://www.cdc.gov/std/prevention/lowdown/the_lowdown_infographic_poster_30x20.pdf" target="_blank" rel="noopener noreferrer">Waiting Room Posters (20x30)</a></p>
								</div>
							</div>
						</div>
						<p className="formsNote">*The brochure can be ordered via the complimentary supporting material form:</p>
						<Button className="orangeButton" onClick={() => this.handleSectionChange("orderforms")}>Order Forms</Button>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default StiPatient
